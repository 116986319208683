<template>
  <div class="commodityManagementDeatailPageBox page-info-content">
    <Details-page :list-data="detailObj.propertyList" :item-data="itemData" :table-title="'采购货物明细'" :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { goodsWeightsDetail } from '@/api/goods'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {
        propertyList: []
      },
      itemData: [
        { label: '属性值', prop: 'detailPropertyName' },
        { label: '属性名', prop: 'detailPropertyValue' },
        { label: '状态', prop: 'detailState', child: [{ label: '有效', value: 0 }, { label: '无效', value: 1 }] }
      ],
      detailsItemArr: [
        { label: '品类(一级)', value: 'categoryName' },
        { label: '品种(二级)', value: 'varietyName' },
        { label: '品名(三级)', value: 'goodsName' },
        { label: '规格', value: 'specs' },
        { label: '材质', value: 'materials' },
        { label: '产地', value: 'productions' },
        { label: '件重(吨)', value: 'weights' },
        { label: '长度(米)', value: 'length' },
        { label: '状态', value: 'state', child: [{ id: 1, val: '有效' }, { id: 0, val: '无效' }] },
        { label: '描述', value: 'remark' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/procurementContract/index', name: '采购合同管理' }, { path: '', name: '采购合同详情' }]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      goodsWeightsDetail(this.$route.query.id, res => {
        this.detailObj = res.data
        if (res.data.propertyList) {
          res.data.propertyList.forEach(item => {
            item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
            item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
            item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
          })
        }
        this.detailObj.propertyList = res.data.propertyList || []
      })
    }
  }
}
</script>
<style lang="scss">
.commodityManagementDeatailPageBox{
  .cancelButton{
    text-align: center;
    margin-top: 18px;
  }
}
</style>
